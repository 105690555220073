import React from 'react';
import Header from "./components/Header/Header";
import {Container, createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import './app.css'
import Browser from "./components/Browser/Browser";
import Login from "./components/Login/Login";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from 'notistack';


export const STORAGE_KEY = 'ARCHWARMIA_KEY'
export const ACCESS_LEVEL = 'ARCHWARMIA_ACCESS_LEVEL'

function App() {

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <BrowserRouter>
                    <Container className="app" fixed>
                        <Header/>
                        <Routes>
                            <Route path="/" element={<Browser/>}/>
                            <Route path="login" element={<Login/>}/>
                        </Routes>
                    </Container>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
