import {Box, Button, Typography} from "@mui/material";
import './personElement.css';
import ChurchIcon from '@mui/icons-material/Church';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import React from "react";
import {ACCESS_LEVEL} from "../../App";

export default (props: any) => {

    const person = props.person;

    return (
        <Box sx={{ boxShadow: 3 , padding: 2}} textAlign="left" className="personHolder">
            <Typography component="p">{person.title} {person.ecclesiasticalTitle} <b>{person.firstName} {person.lastName}</b></Typography>
            <Typography component="p" className="roleHolder">{person.role}</Typography>
            {person.parish && <Typography component="p" className="parishHolder"><ChurchIcon />
                {person.parish}
            </Typography>}
            {person.parishLine1 && <Typography component="p" className="contactHolder">
                {person.parishLine1}
            </Typography>}
            {person.parishLine2 && <Typography component="p" className="contactHolder">
                {person.parishLine2}
            </Typography>}

            {person.email && <Typography component="p" className="contactHolder"><EmailIcon />{person.email}</Typography>}
            {person.phone && <Typography component="p" className="contactHolder"><PhoneIcon />{person.phone}</Typography>}
            {localStorage.getItem(ACCESS_LEVEL) === '2' && <Box sx={{marginTop: 2}}>
                <Button variant="contained" fullWidth onClick={props.onClickDetails}>Szczegóły</Button>
            </Box>}
        </Box>
    );
}