import {Box, Button, Container, Grid, LinearProgress, Modal, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {STORAGE_KEY} from "../../App";
import {useNavigate} from "react-router-dom";
import PersonElement from "./PersonElement";
import PersonDetails from "./PersonDetails";

export default () => {

    const [results, setResults] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [searchExpression, setSearchExpression] = useState('')
    const [openDetails, setOpenDetails] = React.useState(false);
    const [personDetails, setPersonDetails] = React.useState(null as any);
    const history = useNavigate();

    async function loadData() {
        setLoading(true)
        const key = localStorage.getItem(STORAGE_KEY) !== null ? localStorage.getItem(STORAGE_KEY) as string : 'no_Code';
        await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/people/search?search=${searchExpression}`, {
            headers: {
                'archwarmia-code': key
            }
        })
            .then(res => res.json())
            .then(res => setResults(res))
            .catch(res => {
                localStorage.clear()
                history('/login');
            })
        setLoading(false)
    }

    async function loadDetails(id: any) {
        setLoading(true)
        const key = localStorage.getItem(STORAGE_KEY) !== null ? localStorage.getItem(STORAGE_KEY) as string : 'no_Code';
        await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/people/getById?id=${id}`, {
            headers: {
                'archwarmia-code': key
            }
        })
            .then(res => res.json())
            .then(res => setPersonDetails(res))
            .catch(res => {
                localStorage.clear()
                history('/login');
            })
        setLoading(false)
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        await loadData();
    };

    useEffect(() => {
        (async () => await loadData())();
    }, [loaded])

    const onChangeText = (e: any) => setSearchExpression(e.target.value)

    const logout = () => {
        localStorage.clear()
        history('/login');
    }

    const onClickDetails = async (id: any) => {
        await loadDetails(id)
        setOpenDetails(true)
    }

    return (
        <React.Fragment>
            <Grid alignItems="center">
                <Container maxWidth="md" sx={{mb: 2}}>
                    <a href="#" onClick={logout} style={{paddingBottom: '10px'}}>Wyloguj</a>
                    <div style={{height: '10px'}}></div>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <TextField fullWidth size="small" label="Wyszukaj"
                                           value={searchExpression}
                                           onChange={onChangeText}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" size="large" fullWidth type="submit">Szukaj</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
                {loading && <LinearProgress sx={{marginBottom: 2}} id='progressBar'/>}
                <Grid container spacing={2}>
                    {results.map((person: any) => {
                        return (
                            <Grid item xs={12} sm={6} md={4}>
                                <PersonElement onClickDetails={() => onClickDetails(person.id)} person={person}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Modal open={openDetails} onClose={() => setOpenDetails(false)}
                   style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Container fixed sx={{
                    backgroundColor: '#fff',
                    padding: 2,
                    paddingBottom: 4,
                    borderRadius: 1,
                    boxShadow: 1,
                    borderLeft: '4px solid #D70B0B',
                    maxHeight: '95%',
                    overflow: "scroll"
                }}>
                    <Box sx={{textAlign: 'right', width: '100%'}}>
                        <Button variant="contained" onClick={() => setOpenDetails(false)}>Zamknij</Button>
                    </Box>
                    {personDetails != null && <PersonDetails personDetails={personDetails}/>}
                </Container>
            </Modal>
        </React.Fragment>
    );
}
