import {Box, Button, Container, Grid, Typography} from "@mui/material";
import React from "react";
import './personDetails.css'
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default (props: any) => {
    const personDetails = props.personDetails;
    return (
        <React.Fragment>
            <Grid container>
                { personDetails.photo != null && <Grid item xs={12} sm={6} md={2}><img src={personDetails.photo} style={{maxWidth: '150px', float: 'left'}}/></Grid>}
                <Grid xs={12} sm={6} md={10} className="person_details">
                    <Typography
                        component="h4" variant="h4">{personDetails.title} {personDetails.ecclesiasticalTitle} {personDetails.academicTitle} <b>{personDetails.firstName} {personDetails.lastName}</b></Typography>
                    {personDetails.birthYear && <Typography component="p"><span>ROK URODZENIA:</span> {personDetails.birthYear}</Typography>}
                    {personDetails.ordainingYear && <Typography component="p"><span>ROK ŚWIĘCENIA:</span> {personDetails.ordainingYear}</Typography>}
                    <Typography component="p"><span>FUNKCJA:</span> {personDetails.role}</Typography>
                    {personDetails.parish && <Typography component="p"><span>PARAFIA:</span> {personDetails.parish} </Typography>}
                    {personDetails.email && <Typography component="p"><span>EMAIL:</span> {personDetails.email}</Typography>}
                    {personDetails.phone && <Typography component="p"><span>TELEFON:</span> {personDetails.phone}</Typography>}
                </Grid>
            </Grid>
            <Box className="person_history">
                {personDetails.roleHistory.map((role:any) => {
                    return (<div>
                        <Typography component="p" className="person_time_role"><span>{role.role}</span> {role.parishName != null && ' - ' + role.parishName}</Typography>
                        <Typography component="p" className="person_time"><AccessTimeIcon/> <span>{role.dateFrom} - {role.dateTo == null && 'obecnie' || role.dateTo != null && role.dateTo}</span></Typography>
                        <hr/>
                    </div>)
                })}
            </Box>
    </React.Fragment>)
}