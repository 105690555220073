import {Button, Container, FormControl, Grid, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {ACCESS_LEVEL, STORAGE_KEY} from "../../App";
import {useNavigate} from "react-router-dom";

export default () => {
    const [code, setCode] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useNavigate();

    const onChangeCode = (e: any) => setCode(e.target.value)

    const onSubmit = async (e:any) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/people/checkCode?code=${code}`)
            .then(res => res)
            .catch(e => e)
        if (response.status === 200) {
            localStorage.setItem(STORAGE_KEY, code)
            localStorage.setItem(ACCESS_LEVEL, await response.text())
            history('/')
        } else {
            enqueueSnackbar('Nieprawidłowy kod', {variant: 'error'})
        }
    }

    return (
        <Grid alignItems="center">
            <Container maxWidth="xs">
                <Typography component="p">Podaj kod dostępu:</Typography>
                <form onSubmit={onSubmit}>
                <TextField type="password" fullWidth label="Kod dostępu"
                           value={code}
                           onChange={onChangeCode}
                />
                <Button type="submit" variant="contained" fullWidth>Wprowadź</Button>
                </form>
            </Container>
        </Grid>
    );
}